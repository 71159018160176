<template>
  <div
    class="
      bg-gray-100
      w-full
      min-h-full
      flex flex-col
      items-center
      justify-center
    "
  >
    <div class="w-48 mb-8">
      <img
        src="~@/assets/images/logo.svg"
        alt="Logo"
        class="w-full">
    </div>
    <div class="w-full max-w-sm">
      <div
        v-if="view === 'initial'"
        class="bg-white shadow-lg rounded p-8 mb-4"
      >
        <form @submit.prevent="submit">
          <div class="mb-4">
            <input-field
              v-model="formData.email"
              type="email"
              placeholder="Your Email"
              label="Email"
            />
          </div>
          <div class="mb-4">
            <input-field
              v-model="formData.password"
              type="password"
              placeholder="Your Password"
              label="Password"
            />
          </div>
          <div
            class="
              p-3
              bg-red-100
              text-red-800
              border border-red-300
              rounded-sm
              mb-6
              font-semibold
              text-sm text-center
            "
            v-if="error"
          >
            {{ error }}
          </div>
          <div class="flex items-center justify-between">
            <default-button
              primary
              :loading="submitting"
              :disabled="submitting"
              class="w-full"
            >
              Sign In
            </default-button>
          </div>
        </form>
        <div class="border-t my-4" />
        <a
          href="/oauth/redirect/google"
          class="default-button px-12 py-3 flex items-center border border-gray-300 font-semibold rounded text-gray-800 mt-2">
          <span
            class="google-g mr-3 -my-px" />
          <span>Logg inn med Google</span>
        </a>
      </div>
      <div
        v-else
        class="bg-white shadow-lg rounded p-8 mb-4">
        <form @submit.prevent="submitToken">
          <div class="mb-4">
            <input-field
              v-model="token"
              type="text"
              pattern="[0-9]*"
              inputmode="numeric"
              maxlength="8"
              centered
              class="text-center"
              placeholder="Token"
              label="Token" />
          </div>
          <div
            class="p-3 bg-red-100 text-red-800 border border-red-300 rounded-sm mb-6 font-semibold text-sm text-center"
            v-if="error">
            {{ error }}
          </div>
          <div class="flex items-center justify-between">
            <default-button
              primary
              :loading="submitting"
              :disabled="submitting"
              class="w-full">
              Submit
            </default-button>
          </div>
        </form>
        <div class="text-center">
          <a
            href="#"
            :class="retryLink"
            @click.prevent="retryToken">{{ retryText }}</a>
        </div>
      </div>
      <p class="text-center text-gray-500 text-xs mb-12">
        &copy;2022 Legevisitt
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  data() {
    return {
      formData: {
        email: null,
        password: null
      },
      token: null,
      submitting: false,
      error: null,
      view: "initial",
      retryEnabled: false,
      retryTimerId: null,
      retryIntervalId: null,
      maxRetryTimerSeconds: 20,
      timer: 20
    }
  },

  methods: {
    submit() {
      this.submitting = true
      this.error = null
      axios.post("/login", this.formData)
        .then(({ data }) => {
          this.submitting = false
          if (data.data.two_factor === true) {
            this.view = "verify"
          } else {
            window.location.replace(data.data.links.next)
          }
        })
        .catch(() => {
          this.error = "Feil brukernavn og/eller passord."
          this.submitting = false
        })
    },

    submitToken() {
      this.submitting = true
      this.error = null
      axios.post("/login/2fa", { token: this.token, id: this.id })
        .then(({ data }) => {
          window.location.replace(data.data.links.next)
        })
        .catch(error => {
          this.error = error.response.data.message
          this.submitting = false
        })
    },
    newToken() {
      this.submitting = true
      this.error = null
      axios.post("/login/2fa/retry")
        .then(() => {
          this.submitting = false
        })
        .catch(() => {
          this.error = "Feil med sending av ny engangskode."
          this.submitting = false
        })
    },

    retryToken() {
      this.stopRetryTimers()
      this.newToken()
      this.startRetryTimers()
    },
    startRetryTimers() {
      this.retryEnabled = false
      this.timer = this.maxRetryTimerSeconds
      this.retryTimerId = window.setTimeout(() => {
        this.retryEnabled = true
        this.stopRetryTimers()
      }, this.maxRetryTimerSeconds * 1000)
      this.retryIntervalId = window.setInterval(() => {
        this.timer -= 1
      }, 1000)
    },
    stopRetryTimers() {
      window.clearTimeout(this.retryTimerId)
      window.clearInterval(this.retryIntervalId)
    }
  },

  computed:
  {
    retryText() {
      if (this.retryEnabled) {
        return "Send ny engangskode"
      }
       return "Det kan ta litt tid før du får engangskode. Du kan trykke her for å få ny kode om " + this.timer + "s."
    },
    retryLink() {
      if (this.retryEnabled) {
        return "p-3 inline-block text-sm text-gray-800 hover:underline"
      }
      return "p-3 inline-block text-sm text-gray-800 hover:underline disabled"
    }
  },
  unmounted() {
    this.stopRetryTimers()
  },
  watch: {
    view(value) {
      if (value === "verify") {
        this.startRetryTimers()
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/tailwind.css";
@import "../../assets/typography/_default.scss";
@import "../../assets/app.scss";
</style>


<style scoped>
.disabled {
  cursor: not-allowed;
  color: gray;
  pointer-events: none;
}

.google-g {
  display: block;
  width: 28px;
  height: 28px;
  background: url('~@/assets/images/google-g.svg') center center no-repeat;
  background-size: 100%;
}
</style>
