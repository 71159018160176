import Vue from "vue"
import App from "./App.vue"

Vue.config.productionTip = false

const globals = require.context("../../components/globals", true, /\.vue$/i)
globals.keys().map(key => Vue.component(key.split("/").pop().split(".")[0], globals(key).default))

new Vue({
  render: h => h(App)
}).$mount("#app")

